<template>
  <div class="mr-5">
    <v-row
      align="center"
      class="ml-1 mr-0 mt-5"
    >
      <v-col
        class="pa-0"
        cols="auto"
      >
        <admin-header page-title="Recent Student Awards"/>
      </v-col>
      <v-spacer/>
      <v-col cols="auto">
        <v-btn
          :href="downloadHref"
          color="primary"
          rounded
        >
          <v-icon
            left
            style="color: white;"
          > mdi-download
          </v-icon>
          <span style="color: white;">Download This List</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :footer-props="{ itemsPerPageOptions: [5, 10] }"
      :headers="headers"
      :items="schools"
      :loading="loading"
      :options.sync="options"
      :search="search"
      :server-items-length="serverItemsLength"
      :single-select="true"
      class="elevation-2 mt-3"
      fixed-header
      height="100vh"
      item-key="id"
    >
      <template v-slot:top>
        <v-row>
          <v-col
            lg="6"
            md="8"
          >
            <v-text-field
              v-model="search"
              background-color="white"
              class="mx-4"
              label="Search on anything"
              outlined
              prepend-inner-icon="mdi-magnify"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
                <span class="text-apsto-teal">
                    There are no awards offered within the last 30 days on file.
                </span>
      </template>
      <template
        #item.name="{ item }"
        style="vertical-align: top;"
      >
        <div class="text-left pt-2">{{ item.name }}</div>
        <div class="text-left">{{ item.primary_user_name }}</div>
        <div class="text-left">
          <a
            :href="'/impersonate/take/' + item.primary_user_id"
            style="color: var(--apsto-teal); font-weight: 700;"
          >
            {{ item.primary_user_email }}
          </a>
        </div>
        <div class="text-left">{{ item.contact_phone }}</div>
        <div
          v-if="item.application_student_awards_count"
          class="text-left"
        >
          <div
            v-if="item.application_student_awards_count === 1"
            class="text-left"
          >
            {{ item.application_student_awards_count | formatNumber }} award offered for
            {{ item.application_student_awards_sum | formatCurrencyWithCents }}
          </div>
          <div v-else>
            {{ item.application_student_awards_count | formatNumber }} awards offered totaling
            {{ item.application_student_awards_sum | formatCurrencyWithCents }}
          </div>
        </div>
      </template>
      <template #item.application_student_awards="{ item }">
        <v-simple-table class="pt-2">
          <thead>
          <th class="col1 text-apsto-teal">Date</th>
          <th class="col4 text-apsto-teal">Name</th>
          <th class="col2 text-apsto-teal">Amount</th>
          <th class="col4 text-apsto-teal">Household</th>
          <th class="col3 text-apsto-teal">Status</th>
          </thead>
          <tbody>
          <tr
            v-for="(award, index) in item.application_student_awards"
            :key="index"
          >
            <td class="col1">
              {{ displayDate(award.created_at) }}
            </td>
            <td class="col4">
              {{ award.name }}
            </td>
            <td class="col2">
              {{ award.amount | formatCurrencyWithCents }}
            </td>
            <td class="col4">
              {{ award.household_name }}
            </td>
            <td class="col3">
              <table v-if="!award.application_student_award_actions">
                No response
              </table>
              <table v-else>
                <tr
                  v-for="(action, actionIndex) in award.application_student_award_actions"
                  :key="actionIndex"
                >
                  <td
                    v-if="action.action === 1"
                    class="pb-2"
                  >
                    <div>
                      Accepted on {{ displayDate(action.created_at) }} by {{ action.user_name }}
                    </div>
                    <div>
                      Amount: {{ action.amount | formatCurrencyWithCents }}
                    </div>
                  </td>
                  <td
                    v-else
                    class="pb-2"
                  >
                    <div>
                      Declined on {{ displayDate(action.created_at) }} by {{ action.user_name }}
                    </div>
                    <div> Reason: {{ action.award_declination_label }}</div>
                    <div v-if="action.comments">Comments: {{ action.comments }}</div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import {GET_RECENT_STUDENT_AWARDS} from "../../../store/actions.type";

export default {
  name: "AdminStudentAwardsRevise",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      dialog: false,
      headers: [
        {text: 'School', value: 'name'},
        {text: 'AWARD', value: 'application_student_awards'},
      ],
      loading: false,
      pageCount: 0,
      search: "",
      serverItemsLength: 0,
      options: {
        sortBy: ['name'],
        sortDesc: [false],
        page: 1,
      },
    }
  },
  computed: {
    downloadHref() {
      return '/api/admin/student-awards/recent/download';
    },
    schools() {
      return this.$store.getters.currentAdminRecentStudentAwards['collection'];
    },
  },
  methods: {
    getSchools(why) {
      console.log(`getSchools: ${why}`);
      if (this.loading == true) return;

      this.loading = true;
      let page = this.options.page;
      let itemsPerPage = this.options.itemsPerPage;
      let search = this.search;
      let sortBy = this.options.sortBy[0];
      let sortDesc = this.options.sortDesc[0];
      this.$store.dispatch(GET_RECENT_STUDENT_AWARDS, {page, itemsPerPage, sortBy, sortDesc, search})
        .then((data) => {
          this.serverItemsLength = data.totalRecords;
          this.loading = false;
        })
    },
  },
  watch: {
    search() {
      this.page = 1;
      this.getSchools('search');
    },

    options(curr, prev) {
      const watchProps = ['page', 'itemsPerPage', 'sortBy', 'sortDesc']

      for (let i = 0; i < watchProps.length; i++) {
        const key = watchProps[i]

        if (prev[key] !== curr[key]) {
          return this.getSchools('options');
        }
      }
    }


  }
}
</script>

<style scoped>
thead th {
  border-bottom: 1px dotted #808080;
}

.col1 {
  padding-left: 0 !important;
  text-align: left;
  vertical-align: top;
  width: 100px;
}

.col2 {
  padding-left: 0 !important;
  padding-right: 20px;
  text-align: right;
  vertical-align: top;
  width: 100px;
}

.col3 {
  padding-left: 0 !important;
  text-align: left;
  vertical-align: top;
  width: 300px;
}

.col4 {
  padding-left: 0 !important;
  text-align: left;
  vertical-align: top;
  width: 200px;
}

::v-deep .text-start {
  vertical-align: top;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: transparent;
  cursor: default;
}
</style>
