import { render, staticRenderFns } from "./AdminSchoolsReleaseAwardOffers.vue?vue&type=template&id=d9a55bd4&scoped=true&"
import script from "./AdminSchoolsReleaseAwardOffers.vue?vue&type=script&lang=js&"
export * from "./AdminSchoolsReleaseAwardOffers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d9a55bd4",
  null
  
)

export default component.exports