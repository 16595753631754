<template>
  <v-row>
    <v-col>
      <div v-if="pendingAwardsCount" class="px-4">
        <div class="text-h7-mulish">
          Click below when you're ready to send an email to the schools notifying them of newly offered awards.
          This action cannot be undone.
          <div class="mt-4">
            You may want to <a :href="downloadHref" style="color: var(--apsto-teal);">check the list of pending
            awards</a> one last time for any pending awards from last month that need to be
            deleted before you release them.
          </div>
        </div>
        <div class="mb-2 mt-4">
          <v-btn
            :disabled="disabled"
            color="primary"
            rounded
            @click="submit"
          >
            <v-icon
              left
              style="color: white;"
            >
              mdi-email
            </v-icon>
            <span style="color: white;">I'm ready: Notify Schools about new awards</span>
          </v-btn>
        </div>
      </div>
      <div v-else class="px-4">
        <div class="text-h7-mulish">
          There are no pending awards to release.
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import {GET_PENDING_STUDENT_AWARDS_COUNT, RELEASE_AWARD_OFFERS} from "../../../store/actions.type";

export default {
  name: "AdminSchoolsReleaseAwardOffers",
  components: {
    AdminHeader
  },
  computed: {
    disabled() {
      return this.pendingAwardsCount === 0;
    },
    downloadHref() {
      return '/api/admin/student-awards/pending/download';
    },
    pendingAwardsCount() {
      return this.$store.getters.currentAdminPendingStudentAwardsCount;
    },
  },
  methods: {
    submit() {
      this.$store.dispatch(RELEASE_AWARD_OFFERS).then(() => {
        this.$router.push('/dashboard/admin');
      })
    }
  },
  mounted() {
    this.$store.dispatch(GET_PENDING_STUDENT_AWARDS_COUNT);
  }
}
</script>

<style scoped>

</style>
