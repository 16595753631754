<template>
    <loading v-if="loading"/>
    <div
        v-else
        class="mr-5"
    >
        <v-row
            align="center"
            class="ml-1 mt-5"
        >
            <v-col cols="auto">
                <admin-header page-title="Deposits"/>
            </v-col>
            <v-spacer/>
            <v-col cols="auto">
                <v-btn
                    color="primary"
                    @click="createItem"
                >
                    Add Deposit
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="deposits"
                    :search="search"
                    :single-select="true"
                    class="elevation-2 mt-3"
                    item-key="id"
                    @click:row="editItem"
                >
                    <template v-slot:top>
                        <v-row>
                            <v-col md="6">
                                <v-text-field
                                    v-model="search"
                                    background-color="white"
                                    class="mx-4"
                                    label="Search on anything"
                                    outlined
                                    prepend-inner-icon="mdi-magnify"
                                />
                            </v-col>
                        </v-row>
                    </template>
                    <template #item.amount="{ item, index }">
                        <div v-if="!index">{{ item.amount | formatCurrencyWithCents }}</div>
                        <div v-else>{{ item.amount | formatNumberWithCents }}</div>
                    </template>
                    <template #item.school_id="{ item }">
                        <div v-if="item.deposit_type_enum === 1">
                            <div v-if="item.school_id">{{ item.school.name }}</div>
                        </div>
                        <div v-else>
                            <div v-if="item.deposit_source_enum === 1">Amazon Smile</div>
                            <div v-if="item.deposit_source_enum === 2">Blackbaud</div>
                            <div v-if="item.deposit_source_enum === 3">Double The Donation</div>
                            <div v-if="item.deposit_source_enum === 4">Cybergrants</div>
                            <div v-if="item.deposit_source_enum === 5">Benevity</div>
                            <div v-if="item.deposit_source_enum === 6">American Online Giving Fund</div>
                            <div v-if="item.deposit_source_enum === 7">Other Donors</div>
                        </div>
                    </template>
                    <template #item.deposit_type_enum="{ item }">
                        <div v-if="item.deposit_type_enum === 1">Award Refund</div>
                        <div v-if="item.deposit_type_enum === 2">Matching Gift</div>
                    </template>
                    <template #item.user_id="{ item }">
                        <div>{{ item.user.first_name }} {{ item.user.last_name }}</div>
                    </template>
                    <template #item.date_deposited="{ item }">
                        <div>{{ displayDate(item.date_deposited) }}</div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-dialog
                    v-model="createEditDialog"
                    :fullscreen="isMobile"
                    :max-width="dialogMaxWidth"
                >
                    <admin-deposit
                        @cancelled="createEditDialog = false"
                        @done="createdOrEditedItem"
                    />
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AdminHeader from "../main/AdminHeader";
import Loading from "../../../components/shared/Loading";
import {GET_ADMIN_DEPOSITS} from "../../../store/actions.type";
import {SET_ADMIN_DEPOSIT} from "../../../store/mutations.type";
import AdminDeposit from "../../../components/admin/deposits/AdminDeposit";

export default {
    name: "AdminDepositsList",
    components: {
        AdminDeposit,
        AdminHeader,
        Loading
    },
    data() {
        return {
            createEditDialog: false,
            dialog: false,
            dialogDelete: false,
            editDialog: false,
            editedItem: false,
            loading: true,
            search: "",
            temporaryItem: null,
            tooltipLoading: true,
            defaultDeposit: {
                amount: null,
                date_deposited: null,
                deposit_source_enum: 1,
                deposit_type_enum: 1,
                school_id: null,
            },
            headers: [
                {text: 'Date', align: 'start', value: 'date_deposited'},
                {text: 'Deposit Type', align: 'start', value: 'deposit_type_enum'},
                {text: 'Received From', align: 'start', value: 'school_id'},
                {text: 'Amount', align: 'end', value: 'amount'},
                {text: 'Entered By', value: 'user_id', align: 'center', sortable: false}
            ],
        }
    },
    computed: {
        deposits() {
            return this.$store.getters.currentAdminDeposits;
        },
        error() {
            return this.$store.getters.getMessage("admin");
        },
        errors() {
            return this.$store.getters.getErrors("admin");
        },
    },
    methods: {
        createItem() {
            this.$store.commit(SET_ADMIN_DEPOSIT, this.defaultDeposit);
            this.createEditDialog = true;
        },
        editItem(item) {
            this.$store.commit(SET_ADMIN_DEPOSIT, item);
            this.createEditDialog = true;
        },
        getReceipts() {
            this.loading = true;
            this.$store.dispatch(GET_ADMIN_DEPOSITS)
                .then(() => {
                    this.loading = false;
                });
        },
        createdOrEditedItem() {
            this.getReceipts();
            this.createdOrEditedItem = false;
            this.createEditDialog = false;
        }
    },
    mounted() {
        this.getReceipts();
    },
}
</script>

<style scoped>
::v-deep tbody td {
    height: 60px !important;
}

::v-deep tbody tr {
    cursor: pointer;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: var(--apsto-teal-very-light);
}
</style>
